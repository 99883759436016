<template>
    <div>
        <el-form :model="form" :rules="rules" status-icon label-width="100px"  ref="form">
            <el-form-item label="提现账号" prop="account_id">
                <el-select v-model="form.account_id" placeholder="请选择" >
                    <el-option v-for="item in account" :key="item.id" :label="item.name" :value="item.id">{{ item.name }}</el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="税源地" prop="service_id">
                <el-select v-model="form.service_id" placeholder="请选择" @change="serviceChange" >
                    <el-option v-for="item in service" :key="item.id" :label="item.name" :value="item.id">
                        <span style="float: left">{{ item.name }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.balance }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="提现金额" prop="balance">
                <el-input  class="no-number" type="number"  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))" v-model="form.balance"></el-input>
                <div style="color:#e6a23c">该税源地最多可提现{{balance}}元</div>	
            </el-form-item>
        
        </el-form>
        <div class="drawer-footer">
            <Button style="margin-right: 8px" @click="reset('form')">重置</Button>
            <Button type="primary" @click="onSubmit('form')">提交</Button>
        </div>
    </div>
</template>
<script>
	import {request} from "@/library/request.js";
    export default{
        data(){
            var checkAge = (rule, value, callback) => {
                if (!value||value<=0) {
                    return callback(new Error('提现金额不能为空'));
                }
                if (value<500) {
                    return callback(new Error('提现金额不可低于500'));
                }
            }
            return{
                balance:0,
                service:[],
                account:[],
                form:{
                    service_id:"",
                    balance:0.00,
                    account_id:"",
                },
                rules:{
                    account_id: [
                        {required: true, message: '提现账户不能为空', trigger: 'change'},
                    ],
                    balance: [
                        {required: true, message: '提现金额不能为空', trigger: 'blur'},
                    ],
                    service_id: [
                        {required: true, message: '请选择税源地', trigger: 'change'},
                    ],
                }
            }
        },
        methods:{
            onSubmit(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.save()
                    } else {
                        return false;
                    }
                });
            },
            save(){
                var that = this
                this.loading = true
                return request({
                    url:'finance/withdrawal',
                    method:"post",
                    params:this.form
                }).then(response  => {
                    this.loading = false
                    if(response.code==200){
                        this.$Message.success({
                            content:response.msg,
                            duration:1.5,
                            onClose:function(){	
                                that.$emit('routerCancel', true)
                            }
                        })
                       
                    }else{
                        this.$Message.error({
                            content:response.msg,
                            duration: 3
                        })
                    }
                }).then(err => {
                    if(err){
                        this.loading = false
                        this.$Message.error("系统异常，请稍后重试")
                    }
                })
            },
            serviceChange(res){
				const that = this
				this.service.forEach(function(e){
					if(e.id==res){
						that.balance = e.balance-0
                        console.log( e.balance)
						that.form.balance=  e.balance.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
					}
				})
			},
            reset(formName){
                this.balance = 0
				this.$refs[formName].resetFields();
			},
            detail(){
                this.loading = true
                return request({
                    url:'finance/withdrawal',
                    method:"get",
                }).then(response  => {
                    this.loading = false
                    if(response.code==200){
                        this.service = response.data.service
                        this.account = response.data.account
                    }else{
                        this.$Message.error({
                            content:response.msg,
                            duration: 3
                        })
                    }
                }).then(err => {
                    if(err){
                        this.loading = false
                        this.$Message.error("系统异常，请稍后重试")
                    }
                })
            },
        },
        mounted() {
           this.detail()
		},
        watch:{
            "form.balance":{
                handler(newVal,oldVal){
					if(newVal>this.balance){
                        this.form.balance=this.balance
                    }else{
                        let temp = newVal.toString();
                        temp = temp.replace(/。/g, ".");
                        temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
                        temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
                        temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
                        temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
                        temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
                        console.log(temp)
                        this.form.balance=temp
                    }
                }
            }
        }
    }
</script>
<style scoped>
    .drawer-footer{
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 10px 16px;
		text-align: right;
		background: #fff;
	}
    /*在Chrome下移除input[number]的上下箭头*/


</style>
<style>
.no-number::-webkit-outer-spin-button,
.no-number::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}
 
.no-number input[type="number"]::-webkit-outer-spin-button,
.no-number input[type="number"]::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}
 
/*在firefox下移除input[number]的上下箭头*/
.no-number {
  -moz-appearance: textfield;
}
 
.no-number input[type="number"] {
  -moz-appearance: textfield;
}</style>